import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PagesOverview = makeShortcode("PagesOverview");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`These values are what enable brands to differentiate
as brands are given the freedom to adapt these core values according to their
branding strategy.`}</p>
    <p>{`Foundations are expressed under the form of values for color, typography,
spacing, shadows, elevation, and icons. These values are then consumed by the
component tokens and components itself, meaning that a change in one of these
primitive values will automatically translate itself to it being changed
everywhere it was used. A concrete example: say that the primary color for a
brand was to be changed to a slightly darker value. This change will result in
all components using the primary color to be updated automatically. E.g. where
the button used to be a lighter blue shade, the update will now render all
buttons slightly darker blue.`}</p>
    <p>{`These foundations exist both in the design space as in a development
environment. Design-wise, they are represented as color, layer and text styles
in our design toolkit. On the technical side, they are represented as a set of
JSON values, that are subsequently translated to tokens for a specific
programming language.`}</p>
    <PagesOverview ParentPage="Foundations" mdxType="PagesOverview" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      